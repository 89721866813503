<template>
  <div class="page-container">
    <div class="title-bar">
      <BrandLogo :width="70" :height="70" :image="partner.image" />
      <div class="title-bar-content">
        <a @click="createPdf()" class="download-icon">
          <VueIcon
            :iconName="icon.share.name"
            :width="icon.share.width"
            :height="icon.share.height"
          />
        </a>
      </div>
    </div>
    <hr />
    <VueHeadline class="products-headline" level="3" weightLevel="6"
      >Güncel Ürün ve Fiyat Listesi</VueHeadline
    >
    <div class="table-top-wrapper">
      <div class="table-info">
        <p>{{ priceListInfoText }}</p>
        <p>
          Son Güncelleme Tarihi: <b>{{ updateDate }}</b>
        </p>
      </div>
      <div class="search-wrapper">
        <i data-v-4c13b0be="" data-v-b4ccab6e="" class="iconfont iconsearch"></i>
        <input placeholder="Ara" class="search-input" type="text" @input="search($event)" />
      </div>
    </div>
    <vue-table-dynamic :params="params" ref="table" class="data-table" @sort-change="sortChange">
      <template v-slot:column-0="{ props }">
        <img class="product-img" :src="props.cellData" />
      </template>
    </vue-table-dynamic>
  </div>
</template>
<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import VueTableDynamic from 'vue-table-dynamic';
import dateUtils from '@/mixins/dateUtils.js';
import { Pladis } from '@/services/Api/index.js';
import '@/assets/ulker/Roboto-Regular-normal.js';
import { jsPDF } from 'jspdf';
require('jspdf-autotable');
import { getPartnerImage } from '@/utils/partnerImageUtils';

export default {
  name: 'List',
  components: {
    VueIcon,
    BrandLogo,
    VueTableDynamic,
    VueHeadline,
  },
  mixins: [dateUtils],
  data() {
    return {
      partner: {
        code: 'HORIZON',
        image: this.getPartnerImage(),
      },
      params: {
        data: [
          [
            'Ürün Resmi',
            'Ürün Adı',
            'Tavsiye Satış Fiyatı',
            'Alış Fiyatı',
            'Ön Sipariş İçeriği',
            'Kategori',
            'Marka',
            'Ürün Kodu',
          ],
        ],
        header: 'row',
        border: true,
        search: this.search,
        sort: [1, 2, 3, 4, 5, 6, 7],
        pagination: true,
        pageSize: 50,
        columnWidth: [
          { column: 0, width: 75 },
          { column: 1, width: 110 },
          { column: 2, width: 80 },
          { column: 3, width: 100 },
          { column: 4, width: 80 },
          { column: 5, width: 80 },
        ],
        fixed: 1,
        scrollbar: 'hidden',
        rowHeight: 100,
        headerHeight: 60,
      },
      priceListInfoText: '',
      createDate: '',
    };
  },
  computed: {
    icon() {
      return {
        share: ICON_VARIABLES.share,
        print: ICON_VARIABLES.print,
      };
    },
    updateDate() {
      return this.toLocaleDateTimeString(this.createDate);
    },
  },
  mounted() {
    this.getProductsAndPriceList();
  },
  methods: {
    getPartnerImage() {
      return getPartnerImage('horizon');
    },
    async getProductsAndPriceList() {
      try {
        const response = await Pladis.getProductsAndPriceList('5');
        const { Data } = response.data;

        if (Data) {
          const { priceList, priceListInfoText, createDate } = Data;

          const formatPrice = price => {
            const [amount, ...rest] = price.split(' ');
            return `₺${Number(amount)
              .toFixed(2)
              .replace('.', ',')} ${rest.join(' ')}`.replace(/\s/g, '');
          };

          const formatRetailPrice = price => `₺${price.toFixed(2).replace('.', ',')}`;
          const formatUnit = unit => `${unit} adet`;

          const data = priceList.map(item => [
            item.image,
            item.description,
            formatRetailPrice(item.reatilPrice),
            formatPrice(item.price),
            formatUnit(item.unit),
            item.category,
            item.product,
            item.productCode,
          ]);

          this.params.data = [...this.params.data, ...data];
          this.priceListInfoText = priceListInfoText;
          this.createDate = createDate;

          this.fixTable();
          this.sortChange(1, 'ascending');
        }
      } catch (error) {
        console.error('Error fetching products and price list:', error);
      }
    },
    fixTable() {
      let sizeText = document.querySelector('.size-text');
      sizeText.textContent = sizeText.textContent.replace('/page', ' ürün / sayfa');
      let sizeItems = document.querySelectorAll('.size-item');
      sizeItems.forEach(i => {
        i.textContent = i.textContent.replace('/page', ' ürün / sayfa');
        i.addEventListener('click', () => {
          sizeText.textContent = i.textContent.match(/\d+/)[0] + ' ürün / sayfa';
        });
      });
      let scrollBar = document.querySelector('.vue-scrollbar');
      let headerBar = document.querySelector('.v-table-header-wrap');

      scrollBar.addEventListener('scroll', () => {
        let fixedBar = document.querySelector('.v-table-fixed');
        headerBar.scrollLeft = scrollBar.scrollLeft;
        if (scrollBar.scrollLeft > 0) {
          fixedBar.classList.add('is-show-shadow');
        } else {
          fixedBar.classList.remove('is-show-shadow');
        }
      });
      headerBar.addEventListener('scroll', () => {
        scrollBar.scrollLeft = headerBar.scrollLeft;
      });

      let els = document.querySelectorAll('.sort-btns');
      for (var i = 0; i < els.length; i++) {
        els[i].addEventListener('click', e => {
          for (var i = 0; i < els.length; i++) {
            els[i].classList.remove('active');
          }
          e.target.classList.add('active');
        });
      }
    },
    search(e, included, excluded, transform) {
      let searchValue = e.target.value;
      if (!(this.$refs.table.tableData && this.$refs.table.tableData.rows)) return;
      searchValue = String(searchValue);
      this.$refs.table.tableData.rows.forEach(row => {
        if (row && row.cells) {
          if (!searchValue) {
            return (row.show = true);
          }
          let matched = row.cells.some((cell, index) => {
            const transformItem = Array.isArray(transform)
              ? transform.find(
                  item => item && item.column === index && typeof item.method === 'function',
                ) || null
              : null;
            const cellData = transformItem
              ? transformItem.method(cell.data, index, { ...row })
              : cell.data;
            return this.removeTurkishChars(String(cellData))
              .toLocaleLowerCase()
              .includes(this.removeTurkishChars(searchValue.toLocaleLowerCase()));
          });
          row.show = !!matched;
        }
      });
      this.$refs.table.updateActivatedRows();
      this.$refs.table.$nextTick(this.$refs.table.updatePagination);
    },
    removeTurkishChars(text) {
      let returnText = text
        .replace(/ı/g, 'i')
        .replace(/ö/g, 'o')
        .replace(/ü/g, 'u')
        .replace(/ç/g, 'c')
        .replace(/ş/g, 's')
        .replace(/I/g, 'i')
        .replace(/İ/g, 'I')
        .replace(/Ö/g, 'O')
        .replace(/Ü/g, 'U')
        .replace(/Ç/g, 'C')
        .replace(/Ş/g, 'S')
        .toLowerCase();
      return returnText;
    },
    createPdf(isPrint) {
      var doc = new jsPDF();
      let new_arr = [];
      for (var i = 0, j = this.params.data.length; i < j; i++) {
        new_arr.push(this.params.data[i]);
      }
      new_arr = this.params.data.map(key => Object.values(key));
      new_arr.shift();
      new_arr.map(i => i.shift());
      doc.setFontSize(11);
      doc.setFont('Roboto-Regular');
      doc.setTextColor(100);
      var text1 = doc.splitTextToSize(this.priceListInfoText, 300, {});
      doc.text(text1, 14, 10);
      let text2 = doc.splitTextToSize('Son Güncelleme Tarihi: ' + this.updateDate, 300, {});
      doc.text(text2, 14, 15);
      doc.autoTable({
        styles: {
          minCellHeight: 14,
          valign: 'middle',
          font: 'Roboto-Regular',
          fontStyle: 'normal',
        },
        columnStyles: {
          1: { halign: 'center' },
          2: { minCellWidth: 30, valign: 'middle', halign: 'center' },
        },
        head: [
          [
            'Ürün Adı',
            'Tavsiye Edilen Satış Fiyatı',
            'Alış Fiyatı',
            'Ön Sipariş İçeriği',
            'Kategori',
            'Marka',
            'Ürün Kodu',
          ],
        ],
        body: new_arr,
        startY: 20,
        showHead: 'firstPage',
      });
      if (isPrint) {
        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
      } else {
        doc.save('horizon-guncel-fiyat-ve-urun-listesi.pdf');
      }
    },
    sortChange(index, value) {
      let head = this.params.data[0];
      if (value === 'ascending' && index !== 2 && index !== 5) {
        this.params.data.shift();
        this.params.data
          .sort(function(a, b) {
            return (
              isFinite(a[index][0]) - isFinite(b[index][0]) ||
              a[index].toString().localeCompare(b[index].toString(), undefined, {
                numeric: true,
                sensitivity: 'base',
              })
            );
          })
          .unshift(head);
      }
      if (value === 'descending' && index !== 2 && index !== 5) {
        this.params.data.shift();
        this.params.data
          .sort(function(a, b) {
            return (
              isFinite(a[index][0]) - isFinite(b[index][0]) ||
              b[index].toString().localeCompare(a[index].toString(), undefined, {
                numeric: true,
                sensitivity: 'base',
              })
            );
          })
          .unshift(head);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.products-headline {
  margin-top: 20px;
  padding-left: 20px;
}
@mixin flex-center {
  display: flex;
  align-items: center;
}
.page-container {
  .content-row {
    height: unset;
  }
  .gray-bg {
    background: #f8f9f9;
    margin-bottom: 30px;
    padding: 30px 0;
  }
  /deep/ .crumbs-holder {
    padding-left: 0;
  }
  .price-list-toolbar {
    padding: palette-space-level(20);
  }
  .actions {
    a {
      width: inherit;
    }
  }
  .title-bar {
    @include flex-center;
    margin-bottom: palette-space-level(5);
    .title-bar-content {
      @include flex-center;
      position: relative;
      width: 100%;
      justify-content: space-between;
      padding-left: palette-space-level(10);
      padding-right: palette-space-level(10);
      .title-text {
        width: 400px;
      }
    }
    ul.title-bar-icons {
      position: absolute;
      right: 0;
      list-style-type: none;
      padding: 0;
      margin: 0 15px 0 0;
      li {
        float: left;
        margin-left: palette-space-level(30);
        cursor: pointer;
        a {
          .title-bar-icon-text {
            display: inline-block;
          }
        }
        .title-bar-icon {
          svg {
            position: relative;
            top: 2px;
            width: 18px;
            height: 18px;
            margin-right: palette-space-level(10);
          }
        }
      }
    }
  }
}
.product-img {
  max-height: 100%;
}
@mixin flex-center {
  display: flex;
  align-items: center;
}
.title-bar {
  @include flex-center;
  padding: palette-space-level(20);
  margin-bottom: palette-space-level(5);
  .title-bar-content {
    @include flex-center;
    position: relative;
    width: 100%;
    justify-content: space-between;
    padding-left: palette-space-level(10);
    padding-right: palette-space-level(10);
    .download-icon {
      position: absolute;
      right: 0;
    }
  }
}
hr {
  height: 1px;
  width: 100%;
  opacity: 0.5;
  border-top: solid 1px #b4c2d3;
}
</style>

<style>
@media print {
  @page {
    margin: 0;
  }
  body * {
    visibility: hidden;
  }
  #priceList * {
    visibility: visible;
  }
  #priceList {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.data-table {
  max-width: 490px;
  margin-bottom: 60px;
}
.table-sort .table-sort-item {
  position: relative;
  left: 5px;
}
.table-sort .sort-btns {
  border-width: 8px !important;
  left: 0 !important;
}
.table-sort .sort-ascending {
  top: -5px !important;
}
.table-sort .sort-descending {
  top: 0 !important;
}
.tools-search {
  width: 100% !important;
  margin-right: 20px !important;
  margin-left: 20px;
}
.v-table-row {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: normal !important;
  color: #79838e;
  background-color: #f8f9f9 !important;
}
.v-table-row.is-header {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600 !important;
  background-color: #e7e7e7 !important;
}

/* .table-cell:first-child {
  height: 80px !important;
} */
.table-cell {
  padding: 5px !important;
  justify-content: center !important;
}
.table-cell-content {
  white-space: initial !important;
  text-align: center !important;
}
.table-cell-inner {
  display: flex !important;
  justify-content: center;
}

.v-table-header-wrap,
.vue-scrollbar {
  overflow: scroll !important;
}
.v-table-header-wrap::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.v-table-row,
.v-table:before {
  border-bottom: 1px solid #b4c2d3 !important;
}
.v-table.v-show-border {
  border-top: 1px solid #b4c2d3 !important;
}
.table-cell.v-show-border,
.v-table-left-line {
  border-left: 1px solid #b4c2d3 !important;
}
.v-table-right-line {
  border-right: 1px solid #b4c2d3 !important;
}
.pagination-size,
.page-item {
  font-size: 14px !important;
  height: 30px !important;
  border-color: #b4c2d3 !important;
}
.pagination-size {
  min-width: 107px !important;
}
.pagination-size .size-options .size-item {
  padding: 3px !important;
}
.pagination-size .size-text {
  margin-right: 0 !important;
}
.vue-input {
  border-color: #b4c2d3 !important;
}
.vue-input:focus {
  border-color: rgb(121, 131, 142) !important;
}
.sort-btns.active.sort-ascending {
  border-bottom-color: #046fdb !important;
}
.sort-btns.active.sort-descending {
  border-top-color: #046fdb !important;
}
.page-item {
  width: 22px !important;
}
.table-top-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #79838e;
  margin-bottom: 10px;
  margin-top: 25px;
  margin-right: 20px;
  margin-left: 20px;
}
.table-info {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 13px;
}
.table-info p:first-child {
  margin-bottom: 5px;
}
.search-wrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.search-input {
  -webkit-appearance: none;
  background-color: transparent;
  background-image: none;
  border-radius: 2px;
  border: 1px solid;
  box-sizing: border-box;
  display: inline-block;
  font-size: 12px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s ease;
  width: 100;
  height: 47px;
  border-color: #b4c2d3;
  color: #79838e;
  padding-left: 30px;
  flex: 1;
}
.search-input::placeholder {
  color: #b4c2d3;
}
.search-input:focus {
  border-color: #79838e;
}
.iconsearch {
  position: absolute;
  left: 5px;
  top: 0;
  color: #b4c2d3;
}
</style>
